/**
 *
 * ConfirmUser Page
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import Spinner from 'components/Spinner';
import SEO from 'components/seo';
import theme from 'utils/theme';

import { hydrateToken } from 'actions/auth';
import { initAPIClient } from 'utils/api';
import { refreshAuthToken } from 'utils/api/auth';
import { confirmUserById } from 'utils/api/users';
import { toastInfo, toastUpdate, toastUpdateSuccess } from 'utils/toastify';

import { sendCustomGaEvent } from 'utils/ga';

const ConfirmUserPage = ({ location, navigate, dispatch, user }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams(location.search.substring(1));
    const token = params.get('token');
    if (token) {
      // update user to isConfirmed
      // log them in
      (async () => {
        const toastId = toastInfo('Confirming user...');
        const decoded = jwtDecode(token);
        initAPIClient(token);
        const res = await confirmUserById(decoded.user.id);
        const newToken = await refreshAuthToken(token);
        toastUpdate(
          toastId,
          'Successfully confirmed account! Signing you in...',
        );
        const userData = await dispatch(hydrateToken(newToken));
        if (userData) {
          toastUpdateSuccess(toastId, 'Successfully signed in!');
          navigate('/account');
          sendCustomGaEvent('Sign Up', 'Verified', 'Signup Verified');
        }
        setIsLoading(false);
      })();
    } else {
      setIsLoading(false);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Forgot Password" />
        {/* <Spinner loading={isLoading} cover /> */}
        <h2>Confirming User...</h2>
        {/* {isLoading && <h2>Confirming User...</h2>} */}
      </Container>
    </ThemeProvider>
  );
};

ConfirmUserPage.propTypes = {
  user: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  navigate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);
export default withConnect(ConfirmUserPage);

const Container = styled.div`
  ${theme.styles.middle}
  color: #000;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
